.btn {
    line-height: 20px;
    padding: 6px 10px;
    background: $red2;
    border-radius: 4px;
    font-size: 18px;
    border: 2px solid $red2;
    outline: none;
    color: white;
}

.btn:hover {
    cursor: pointer;
    background: $red3;
    border: 2px solid $red3;
}

.btn.radio {
    background: $red1;
    border: 2px solid $red1;
}

.btn.radio:hover {
    background: $red2;
    border: 2px solid $red3;
}

.btn.radio.selected {
    background: $red4;
    border: 2px solid $red4;
}

.btn.small {
    line-height: 20px;
    padding: 4px 8px;
    font-size: 15px;
}
