@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-black-webfont.woff2') format('woff2'),
         url('../fonts/lato-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Crimson Text';
    src: url('../fonts/crimsontext-roman-webfont.woff2') format('woff2'),
         url('../fonts/crimsontext-roman-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Light';
    src: url('../fonts/lato-light-webfont.woff2') format('woff2'),
         url('../fonts/lato-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Regular';
    src: url('../fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
