body,
html {
    margin: 0;
    font-family: 'Crimson Text', serif;
    font-size: 20px;
    line-height: 30px;
    height: 100%;
    width: 100%;
    text-align: center;
    color: $text;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato', sans-serif;
}

h1 {
    font-size: 42px;
    line-height: 50px;
    margin-top: 50px;
    margin-bottom: 10px;
}

h2 {
    font-size: 36px;
    line-height: 42px;
    margin-top: 50px;
    margin-bottom: 10px;
}

h3 {
    font-size: 30px;
    line-height: 36px;
    margin-top: 30px;
    margin-bottom: 7px;
}

h4 {
    font-size: 28px;
    line-height: 34px;
    margin-top: 30px;
    margin-bottom: 7px;
}

h5 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 30px;
    margin-bottom: 7px;
}

h6 {
    font-size: 24px;
    line-height: 30px;
    margin-top: 30px;
    margin-bottom: 7px;
}

h1 + p, 
h2 + p, 
h3 + p, 
h4 + p, 
h5 + p, 
h6 + p {
    margin-top: 0;
}

a,
a:visited,
a:focus,
a:active {
    color: $red2;
}

a:hover {
    color: $red2;
}

dt {
    font-weight: bold;
}

.hide {
    display: none;
}

[id]::before {
    height: $headerHeight + 10;
    margin-top: ($headerHeight + 10) * -1;
    content: '';
    display: block;
}

header {
    position: fixed;
    height: $headerHeight;
    vertical-align: top;
    border-bottom: 1px solid $grey2;
    background: white;
    z-index: 100;

    @include containerWidth($sidebarWidth, $centerWidth);
}

header .logo {
    vertical-align: top;
    display: inline-block;
    width: 100px;
    height: $headerHeight;
    line-height: $headerHeight;
    font-family: "Lato";
}

header .logo {
    a,
    a:visited,
    a:focus,
    a:hover,
    a:active {
        color: $red4;
        text-decoration: none;
    }
}

header .tagline {
    margin: 0;
    font-family: "Lato";
    vertical-align: top;
    height: $headerHeight;
    line-height: $headerHeight;
    display: inline-block;
}

header .read-modes {
    margin: 0;
    font-family: "Lato";
    line-height: $headerHeight;
    float: right;
    font-size: 16px;
}

header .read-modes .btn {
    margin-left: 10px;
}

a[target=_blank] {
    position: relative;
}

a[target=_blank]::after {
    display: inline-block;
    text-decoration: none;
    font-family: "ionicons";
    content: $ionicon-var-android-arrow-down;
    font-size: 18px;
    transform: rotate(225deg);
    margin-left: 5px;
}

.code,
code {
    font-family: Consolas,Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,monospace,sans-serif;
    background: $grey2; 
    white-space: pre-wrap;
    font-size: 11px;
    line-height: 14px;
    display: block;
    padding: 4px 8px;
}

code.small {
    display: inline;
}


pre {
    font-size: 16px;
    line-height: 22px;
    display: block;
    padding: 4px 8px;
}

table.borders {
    border-collapse: collapse;
}

table.borders th,
table.borders td {
    border: 1px solid grey;
    padding: 2px 5px;
}

table code,
p code {
    display: inline;
}

p.small {
    font-size: 13px;
    line-height: 21px;
}

blockquote {
    margin-left: 0;
    margin-right: 0;
}

.blockquote,
blockquote p {
    margin: 0;
    border-left: 2px solid black;
    font-style: italic;
    background: $grey1;
    display: inline-block;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
}

cite {
    font-style: normal;
    text-align: right;
    font-size: 18px;
    display: block;
}


ul {
    list-style: none;
}

ul li {
    position: relative;
}

ul li::before {
    content: "•";
    font-size: 28px;
    position: absolute;
    left: -22px;
    top: 0px;
}

ul.numbered li::before {
    content: none;
}

dl dd {
    margin: 0;
}

dl dt {
    margin-top: 10px;
}

.container {
    display: inline-block;
    text-align: left;
    position: relative;

    @include containerWidth($sidebarWidth, $centerWidth);
}

.column1,
.column2,
.column3 {
    display: inline-block;
    vertical-align: top;
    position: relative;
    top: $headerHeight;
}

.column1,
.column1 .contents,
.column3 {
    @include sidebarWidth($sidebarWidth);
}

.column2.large {
    @include sidebarWidth($sidebarWidth + $centerWidth);
}

.column2 {
    width: $centerWidth;
}

.column3 > * {
    display: none;
}

.column3 > *.show {
    display: block;
}

.contents {
    position: fixed;
    top: $headerHeight;
    padding-right: 10px;
    box-sizing: border-box;
}

.contents,
.contents a {
    font-size: 16px;
    font-family: "Lato Regular";
    color: $grey3;
    line-height: 24px;
    text-decoration: none;
}

.contents .title {
    font-size: 20px;
    color: $grey3;
    margin-top: 30px;
}

.contents > ul {
    padding: 0;
    list-style: none;
}

.contents > ul li::before {
    content: '- ';
}

footer.content-bottom {
    padding: 10px 20px 60px 20px;
    background: $grey1;
    margin-top: 80px;
}

.bottom-bar {
    font-family: "Lato Regular";
    font-size: 16px;
    color: black;
    border-bottom: 1px solid $grey2;
    border-top: 1px solid $grey2;
    margin: 20px 0;
}

