$text: rgba(0, 0, 0, 0.8);

$grey1: #f9f9f9;
$grey2: lightgrey;
$grey3: grey;

$red1: #ff7e7e;
$red2: #ff6161;

$red3: #ff5555;
$red4: #fd3c3c;

@mixin sidebarWidth($sbWidth) {
    width: $sbWidth;
}

@mixin containerWidth($sbWidth, $ctWidth) {
    width: $ctWidth + $sbWidth + $sbWidth;
}

$centerWidth: 700px;
$sidebarWidth: 250px;

$headerHeight: 60px;
