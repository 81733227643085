.explanation * {
    white-space: normal;
    word-break: break-word;
}

.explanation .text {
    display: inline-block;
}

.explanation .code {
    font-size: 14px;
    line-height: 14px;
    padding: 2px 4px;
    display: inline-block;
}

.explanation {
    a,
    a:visited,
    a:focus,
    a:active {
        color: $text;
        text-decoration: underline;
    }

    a:hover {
        color: black;
    }
}


.explain {
    color: $red1;
    position: relative;
}

.explain:hover {
    color: $red2;
    cursor: pointer;
}

code.explain {
    color: $red3;
}

code.explain:hover {
    color: $red4;
}

.explanation.show {
    display: block;
}

.explanation {
    display: none;
    text-decoration: none;
    width: 210px;
    float: right;
    clear: right;
    margin-right: -250px;
    margin-top: -19px;
    margin-bottom: 19px;
    padding: 10px;
    background: $red1;
    border-left: 10px solid $red2;
    border-top: 10px solid $red2;
    font-size: 20px;
    line-height: 26px;
    position: relative;
    text-align: left;
    color: $text;
    font-family: "Crimson Text";
}

section > code.explain,
section > code .explain {
    display: block;
}

section > code .explanation,
section > code.explain > .explanation {
    position: absolute;
    top: 5px;
    right: 0px;
}


.explain:hover .explanation {
    cursor: auto;
}


.explain > .explanation::before {
    border-radius: 10px;
    width: 20px;
    height: 20px;
    display: inline-block;
    content: "";
    background: $red2;
    position: absolute;
    left: -20px;
}

.explain > .explanation::after {
    border-radius: 5px;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: "";
    background: $red1;
    position: absolute;
    left: -15px;
    top: 15px;
}
.explanation .text {
    width: 100%;
}

.explanation .code {
}

.explanation .list {
    clear: both;
    float: left;
    position: relative;
    width: 100%;
}

.explanation .list::before {
    padding-left: 5px;
    content: "•";
    font-size: 18px;
    position: relative;
    left: -4px;
    top: 0px;
}

.explanation .list.no-bullet::before {
    content: "";
}
