$dtWidth: 250px;

.fingerprint dt,
.fingerprint dd {
    display: inline-block;
    vertical-align: top;
}

.fingerprint dt {
    width: $dtWidth;
}

.fingerprint dd {
    word-break: break-word;
    width: $centerWidth + $sidebarWidth - $dtWidth - 10px;
}
