@media screen and (max-width: 600px) {
    .read-modes {
        display: none;
    }
}

@media screen and (max-width: 1000px) {
    body > .container {
        width: calc(100% - 40px);
    }

    header {
        width: calc(100% - 40px);
    }

    .column1 {
        display: none;
    }

    .column2,
    .column2.large {
        width: 100%;
        display: block;
    }

    ol,
    ul.numbered {
        padding-left: 20px;
    }

    li > ul {
        padding-left: 20px;
    }

    .table-wrap {
        white-space: pre;
        overflow: scroll;
    }

    .code:not(.explain),
    code:not(.explain) {
        white-space: pre;
        overflow-x: scroll;
    }

}

@media screen and (max-width: 1400px) {
    .explain > .explanation.show {
        width: calc(100% - 30px);
        margin: 0;
        display: block;
        margin-top: 30px;
        top: 0;
        left: 0;
        z-index: 2;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
    .column1,
    .column1 .contents,
    .column3 {
        @include sidebarWidth(330px);
    }

    header,
    .container {
        @include containerWidth(330px, $centerWidth);
    }

    .explain > .explanation {
        width: 290px;
        margin-right: -330px;
    }
}

@media screen and (min-width: 1600px) {
    .column1,
    .column1 .contents,
    .column3 {
        @include sidebarWidth(430px);
    }

    header,
    .container {
        @include containerWidth(430px, $centerWidth);
    }

    .explain > .explanation {
        width: 390px;
        margin-right: -430px;
    }
}

